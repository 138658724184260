import { lazy, Suspense } from "react"
import { Outlet } from "react-router-dom"

import { CONFIG } from "src/config-global"
import { DashboardLayout } from "src/layouts/dashboard"

import { LoadingScreen } from "src/components/loading-screen"

import { AuthGuard } from "src/auth/guard"

import { paths } from "../paths"
import { isJobDetails } from "@/src/utils/storage-available"

// ----------------------------------------------------------------------

const LeadsPage = lazy(() => import("src/pages/dashboard/leadsPage/leadsPage"))
const InterviewsPage = lazy(() => import("src/pages/dashboard/interviewsPage/interviewsPage"))
const ClientsPage = lazy(() => import("src/pages/dashboard/clientsPage/clientsPage"))
const MandatesPage = lazy(() => import("src/pages/dashboard/mandatesPage/mandatesPage"))
const SelectionsPage = lazy(() => import("src/pages/dashboard/selectionsPage/selectionsPage"))
const BulkUploadsPage = lazy(() => import("src/pages/dashboard/bulkUploadPage/bulkUploadPage"))
const InterviewsForm = lazy(() => import("src/pages/dashboard/newInterviewsPage"))
const LeadsForm = lazy(() => import("src/pages/dashboard/newLeadsPage"))
const ClientsForm = lazy(() => import("src/pages/dashboard/newClientsPage"))
const MandatesForm = lazy(() => import("src/pages/dashboard/newMandatePage"))
const SelectionForm = lazy(() => import("src/pages/dashboard/newSelectionPage"))
const AccessManagementPage = lazy(() => import("src/pages/dashboard/accessManagementPage"))
const PageFive = lazy(() => import("src/pages/dashboard/five"))
const PageSix = lazy(() => import("src/pages/dashboard/six"))
const ClientDetailsPage = lazy(() => import("src/pages/dashboard/clientsPage/clientsDetailsPage"))
const MandateDetailsPage = lazy(() => import("src/pages/dashboard/mandatesPage/mandatesDetailsPage"))
const SelectionDetailsPage = lazy(() => import("src/pages/dashboard/selectionsPage/selectionsDetailsPage"))
const JobDetailsPage = lazy(() => import("src/pages/dashboard/jobDetailsPage/jobDetailsPage"))

// ----------------------------------------------------------------------

const LayoutContent = () => {
    const layout = (
        <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
                <Outlet />
            </Suspense>
        </DashboardLayout>
    )
    return isJobDetails() || CONFIG.auth.skip ? <>{layout}</> : <AuthGuard>{layout}</AuthGuard>
}

export const dashboardRoutes = [
    {
        path: "dashboard",
        element: LayoutContent(),
        children: [
            { element: <LeadsPage />, index: true },
            { path: paths.dashboard.interviews, element: <InterviewsPage /> },
            { path: paths.dashboard.interviewForm, element: <InterviewsForm /> },
            { path: paths.dashboard.leadForm, element: <LeadsForm /> },
            { path: paths.dashboard.clients, element: <ClientsPage /> },
            { path: paths.dashboard.selections, element: <SelectionsPage /> },
            { path: paths.dashboard.mandates, element: <MandatesPage /> },
            { path: paths.dashboard.clientForm, element: <ClientsForm /> },
            { path: paths.dashboard.selectionForm, element: <SelectionForm /> },
            { path: paths.dashboard.mandateForm, element: <MandatesForm /> },
            { path: paths.dashboard.bulkUpload, element: <BulkUploadsPage /> },
            { path: paths.dashboard.jobDetails, element: <JobDetailsPage /> },
            {
                path: "group",
                children: [
                    { element: <AccessManagementPage />, index: true },
                    { path: "five", element: <PageFive /> },
                    { path: "six", element: <PageSix /> },
                ],
            },
            { path: paths.dashboard.client.root, element: <ClientDetailsPage /> },
            { path: paths.dashboard.mandate.root, element: <MandateDetailsPage /> },
            { path: paths.dashboard.selection.root, element: <SelectionDetailsPage /> },
        ],
    },
]
