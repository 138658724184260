export type UserRole = "RECRUITER" | "TEAM_LEAD" | "MANAGER" | "ADMIN" | "SUPER_ADMIN"
export const USER_ROLE_ORDER: UserRole[] = ["SUPER_ADMIN", "ADMIN", "MANAGER", "TEAM_LEAD", "RECRUITER"]

export type UserType = {
    id: string
    createdDate: string
    lastModifiedDate: string
    profileImageUrl: string | null
    fullName: string
    email: string
    phoneNumber: string
    status: "ACTIVE" | string
    roles: UserRole[]
    company: {
        id: string
        name: string
        logoUrl: string | null
        websiteUrl: string
    }
    managerId: string | null
    highestRole: UserRole
}

export type AuthState = {
    user: UserType | null
    loading: boolean
}

export type AuthContextValue = {
    user: UserType | null
    loading: boolean
    authenticated: boolean
    unauthenticated: boolean
    checkUserSession?: () => Promise<void>
}
